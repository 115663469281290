<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) You are performing an experiment that requires a constant pH of 5.96. Which of the
        following buffer systems is most appropriate to use for this experiment?
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question538',
  components: {
    ChemicalLatex,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {
          text: 'citric acid/citrate, $\\text{K}_\\text{a}=7.1\\times 10^{-4}$',
          value: 'citrate',
        },
        {
          text: 'benzoic acid/benzoate, $\\text{K}_\\text{a}=4.3\\times 10^{-7}$',
          value: 'benzoate',
        },
        {
          text: 'phosphoric acid/dihydrogen phosphate, $\\text{K}_\\text{a}=4.3\\times 10^{-7}$',
          value: 'dihydrogenPhosphate',
        },
        {
          text: 'carbonic acid/hydrogen carbonate, $\\text{K}_\\text{a}=4.3\\times 10^{-7}$',
          value: 'carbonate',
        },
      ],
    };
  },
};
</script>
